const sundays = [
  ["May 25", "Ford", "6am - 12pm", ""],
  ["June 1", "*Belleville", "6am - 12pm", ""],
  ["June 8", "Chemung", "6am - 12pm", ""],
  ["June 15", "Woodland", "6am - 12pm", ""],
  ["June 22", "Sand", "6am - 12pm", ""],
  ["June 29", "Ford", "6am - 12pm", ""],
  ["July 13", "Whitmore", "6am - 12pm", ""],
  ["July 20", "Devils", "6am - 12pm", ""],
  ["July 27", "Woodland", "6am - 12pm", ""],
  ["Aug 3", "Whitmore", "6am - 12pm", ""],
  ["Aug 10", "Wamplers", "8am - 2pm", ""],
  ["Aug 17", "Chemung", "6am - 12pm", ""],
  ["Aug 24", "Halfmoon", "8am - 2pm", ""],
  ["TBD", "Classic - TBD", "7am - 1pm", ""]
];

const sundayTopFive = [
  ["", ""],
];

const sundayBigBass = [
  ["", ""],
];

const fridays = [
  ["May 30", "Woodland", ""],
  ["June 6", "*Belleville", ""],
  ["June 13", "Chemung", ""],
  ["June 20", "Sand", ""],
  ["June 27", "Lobdell", ""],
  ["July 11", "Woodland", ""],
  ["July 18", "Whitmore", ""],
  ["July 25", "Lobdell", ""],
  ["Aug 1", "*Belleville", ""],
  ["Aug 8", "Sand", ""],
  ["Aug 15", "Whitmore", ""],
  ["Aug 22", "Chemung", ""],
  ["TBD", "Classic", ""]
];

const fridayTopFive = [
  ["", ""],
];

const fridayBigBass = [
  ["", ""],
];


export {
  sundays,
  sundayTopFive,
  sundayBigBass,
  fridays,
  fridayTopFive,
  fridayBigBass
}