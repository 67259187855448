<template>
  <div>
    <top-nav></top-nav>

    <div class="container">
      <h1 class="main-container display-4">Sunday Tournaments - 2025</h1>

      <ul class="nav nav-tabs mb-3 mt-5 display-text">
      <li class="nav-item">
        <a class="nav-link active" data-bs-toggle="tab" href="#schedule">Schedule</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" data-bs-toggle="tab" href="#standings">Standings & Results</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" data-bs-toggle="tab" href="#membership">Membership</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" data-bs-toggle="tab" href="#entry">Entry Fees</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" data-bs-toggle="tab" href="#rules">Rules</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" data-bs-toggle="tab" href="#classic">Classic</a>
      </li>
    </ul>
  
    <div id="myTabContent" class="tab-content">

      <!-- Schedule Tab -->
      <div class="tab-pane fade show active" id="schedule">
        <div class="tab-pane fade show active" id="schedule">
          <div class="images">
            <table id="resultsTable" class="table table-dark col-lg-6">
              <thead>
                <tr>
                  <th class="h4 table-header" scope="col">Date</th>
                  <th class="h4 table-header" scope="col">Lake</th>
                  <th class="h4 table-header" scope="col">Time</th>
                  <th class="h4 table-header" scope="col">Winner</th>
                </tr>
              </thead>
            </table>
            <h5 class="text-warning">*Belleville Lake - Use Huron River DNR Launch</h5>
          </div>
        </div>
      </div>

      <!-- Standings Tab -->
      <div class="tab-pane fade" id="standings">
        <iframe class="resultsIframe"
          src="https://docs.google.com/spreadsheets/d/e/2PACX-1vSSO2keREShQic_9J3OlomZ0V_TMAyZqQDroTTEG24qmO7b_zfGuYqseqy6QoYMcimDWW9GhIDv9K4V/pubhtml?widget=true&amp;headers=false">
        </iframe>
      </div>

      <!-- Membership Tab -->
      <div class="tab-pane fade" id="membership">
        <ul class="display-text">
          <li>There is a $25 one time membership to fish.</li>
          <li class="text-warning"><strong>Children under 18 fish for free.</strong></li>
          <li class="text-warning"><strong>Women fish for free.</strong></li>
        </ul>
      </div>

      <!-- Entry Fees Tab -->
      <div class="tab-pane fade" id="entry">
        <ul class="display-text">
          <li>$90 Per Boat</li>
          <li>Includes $10 for Big Bass</li>
          <li>Includes $10 for the Classic</li>
        </ul>
      </div>

      <!-- Rules Tab -->
      <div class="tab-pane fade" id="rules">
        <ul class="display-text">
          <li class="text-warning"><strong>NO ALCOHOL PERMITTED</strong></li>
          <li>Five (5) fish limit per team.</li>
          <li>Largemouth and Smallmouth Bass, 14 inches, mouth closed and no pinched tails.</li>
          <li>Only ONE LINE in the water per angler at any time.</li>
          <li>Artificial lures only. NO LIVE or DEAD baits are permitted.</li>
          <li>Once fish are brought to the scales there is no bumping. You may check your ruler with the official
            ruler before weigh in starts.</li>
          <li>No culling at the weigh in site, Once your boat is on the trailer you are considered to be at the
            weigh in site.</li>
          <li>Once a fish is measured by one of the officials that is the final call, there will be no second
            chances or re-measuring of the fish. If you disagree with the official call your team will be
            disqualified.</li>
          <li>Short fish will result in losing your biggest fish.</li>
          <li>Dead fish penalty is one (1) pound per dead fish.</li>
          <li>Big fish ties will be split 50/50.</li>
          <li>You must draw your blast off number.</li>
          <li>All livewells will be inspected before launch.</li>
          <li>Life jackets must be worn when the big motor is running.</li>
          <li>All "NO WAKE" zones are to be observed. This includes any channels or canals.</li>
          <li>All competitors must stay outside a reasonable casting distance of another boat already fishing an
            area. Example: If a angler can cast and cross your line you are to close.</li>
          <li>Lights must be on when big motor is running before safe light.</li>
          <li>Late arrivals must find tournament director to pay and have livewell inspected prior to fishing.</li>
          <li>If weather conditions require the tournament to be delayed or canceled the decision will be made at
            the tournament site.</li>
          <li><strong>Belleville Lake</strong> -  No wake must be observed from the Rawsonville launch to the dam. Failure to follow this will result in disqualification of that tournament.</li>
          <li class="text-warning"><strong>TOURNAMENT LAKES ARE OFF LIMITS 24 HOURS BEFORE BLAST OFF TIME.</strong>
          </li>
        </ul>
      </div>

      <!-- Classic Tab -->
      <div class="tab-pane fade col-md-10" id="classic">
        <h4>Sunday Classic Qualifications:</h4>
        <ul class="display-text">
          <li>You must fish (8) tournaments with the same partner to qualify for the classic as a team.</li>
          <li>Each team is allowed one sub one time per year</li>
          <li>Fishing (8) tournaments with different partners or by yourself will qualify you to fish the classic
            alone.</li>
          <li>Fishing alone and paying the full price entry fee will be treated the same as fishing with the same
            partner.</li>
          <li>Classic will be determined by drawing a lake out of a hat.</li>
          <li>Angler of the Year will be on a point system. First place = 10 points down to tenth (10th place) = 1
            point. Big Bass will be worth two (2) points.</li>
        </ul>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import TopNav from '../components/TopNav.vue';
import { sundays } from '../data/results.js';
import { buildTable } from '../js/utilities.js';
export default {
  components: {
    TopNav
  },
  mounted() {
    buildTable("resultsTable", sundays);
  }
};
</script>